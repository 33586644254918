<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Historial de Transacciones Bancarias</h4>
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12">
							<label for="Cuenta">Cuenta</label>
							<Dropdown v-model="IdCuenta" :options="lkpCuentas" optionLabel="descripcion" placeholder="Seleccionar Cuenta..." optionValue="id_cuenta">
								<template #option="slotProps">
									<div class="flex align-items-center">
										<div>[{{ slotProps.option.no_cuenta }}] {{ slotProps.option.descripcion }}</div>
									</div>
								</template>
							</Dropdown>							
						</div>
							<div class="p-field p-col-3">
							<label for="anio">Año</label>
							<Dropdown v-model="anioSel" :options="lkpAnios"/>
						</div>
						<div class="p-field p-col-2 p-md-4">
							<label for="desde">Desde</label>
							<Dropdown v-model="mesIni" :options="lkpMeses" optionLabel="nombre" optionValue="mes"/>
						</div>		
						<div class="p-field p-col-3 p-md-4">
							<label for="hasta">Hasta</label>
							<Dropdown v-model="mesFin" :options="lkpMesesFin" optionLabel="nombre" optionValue="mes"/>
						</div>						
						<div class="p-field p-col-2 p-md-2">
							<Button label="Buscar" icon="pi pi-search" class="p-button-primary p-m-2" @click="buscar" />							
						</div>																							
					</div>	
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="trxs" class="p-datatable-customers" v-model:selection="selectedMovs" :rowClass="rowClass" dataKey="id_movimiento" :paginator="true" :rows="10"
							:globalFilterFields="['nombre', 'no_documento', 'monto', 'referencia', 'movimiento_tipo']" 
							v-model:filters="filters" @filter="filter"
							filterDisplay="row"							
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">							
							<span class="p-input-icon-right">
								<SplitButton v-if="puedeDescargar" label="Descargar" icon="pi pi-download" class="p-ml-2" :model="dowloandItems" />							    
							</span>							
						</div>
					</template>
					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"/>	 -->
					<Column field="fecha" header="Fecha" :sortable="true" headerStyle="width: 12rem">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							{{slotProps.data.fecha}}
						</template>					
					</Column>
					<Column field="movimiento_tipo" header="Tipo"  headerStyle="width: 11rem"/>
					<Column field="no_documento" header="No.Docto"  headerStyle="width: 12rem"/>
					<Column field="nombre" header="Nombre"  headerStyle="width: 15rem"/>
					<Column field="referencia" header="Referencia" headerStyle="width: 15rem"/>
					<Column field="id_moneda" header="Moneda"/>
					<Column field="monto" header="Crédito" headerClass="p-jc-end">
						<template #body="slotProps">							
							<div v-if="slotProps.data.factor==1" style="font-weight: 500;color: green;" class="p-d-flex p-jc-end">{{ formatCurrency(slotProps.data.monto) }}</div>
							<div v-else></div>
						</template>
					</Column>
					<Column field="monto" header="Débito" headerClass="p-jc-end">
						<template #body="slotProps">													
							<div v-if="slotProps.data.factor==-1" style="font-weight: 500;color: red;" class="p-d-flex p-jc-end">{{ formatCurrency(slotProps.data.monto) }}</div>
							<div v-else></div>
						</template>																		
					</Column>										
					<Column field="saldo" header="Saldo" headerClass="p-jc-end">
						<template #body="slotProps">													
							<div style="font-weight: 500;" class="p-d-flex p-jc-end">{{ formatCurrency(slotProps.data.saldo) }}</div>
						</template>												
					</Column>										
					<ColumnGroup type="footer">
						<Row>
							<Column footer="" :colspan="6" footerStyle="text-align:right" />
							<Column :footer="formatCurrency(totalIngresos)" footerStyle="text-align:right"/>			
							<Column :footer="formatCurrency(totalEgresos)" footerStyle="text-align:right"/>	
							<Column footer="" :colspan="1" footerStyle="text-align:right" />		
						</Row>
					</ColumnGroup>		
				</DataTable>

				<Dialog v-model:visible="anularDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="mov">Está seguro que desea anular el documento <br><b>[{{mov.serie}}-{{mov.no_docto}}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="anularDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="anularTrx" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import SeleccionFecha from '../../components/SeleccionFecha.vue';
import BanService from '../../service/ban.service';
import { CURRENT_APP_MODE } from '../../service/constants';
import { utils, writeFileXLSX } from 'xlsx';

export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
	components: { SeleccionFecha },
	data() {
		return {
			appMode: CURRENT_APP_MODE,
			desdeUpdate: 0,
			IdCuenta: null,
			lkpCuentas: null,
			lkpAnios: [],
			lkpMeses: [
				{mes: 1, nombre: "Enero"},
				{mes: 2, nombre: "Febrero"},
				{mes: 3, nombre: "Marzo"},
				{mes: 4, nombre: "Abril"},
				{mes: 5, nombre: "Mayo"},
				{mes: 6, nombre: "Junio"},
				{mes: 7, nombre: "Julio"},
				{mes: 8, nombre: "Agosto"},
				{mes: 9, nombre: "Septiembre"},
				{mes: 10, nombre: "Octubre"},
				{mes: 11, nombre: "Noviembre"},
				{mes: 12, nombre: "Diciembre"}				
			],
			lkpMesesFin: [],
			anioSel: null,		
			mesIni: null,
			mesFin: null,
			hasta: new Date(),
			desde: new Date(),
			trxs: null,
			anularDialog: false,
			puedeAnular: this.$store.getters['auth/tieneAcceso']('CC_ANULAR'),
			puedeImprimir: this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO'),
			puedeEnviarEmail: (this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO')),	
			puedeDescargar: this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO'),
			dowloandItems: [	
    			{label: 'Exportar Listado Docs', icon: 'pi pi-file-excel', command: () => {this.exportarTrxsAXLS()}},			
			],			
			selectedMovs: null,
			secuencia: 1,			
			mov: {},
			loading: false,
			filteredData: [],
			filters: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				fecha: {value: null, matchMode: FilterMatchMode.CONTAINS},
				movimiento_tipo: {value: null, matchMode: FilterMatchMode.CONTAINS},
				documento: {value: null, matchMode: FilterMatchMode.CONTAINS},
				total: { value: null, matchMode: FilterMatchMode.EQUALS }				
			},	
			totalIngresos: 0.0,		
			totalEgresos: 0.0,					
			submitted: false
		}
	},
	mounted() {
		
		var anioActual = new Date().getFullYear();
		var mesActual = new Date().getMonth();
		this.lkpAnios = [];

		for(var i=0; i < 10; i++) {
			this.lkpAnios.push(anioActual - i);
		}
		// this.lkpAnios = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015];

		this.anioSel = this.lkpAnios[0];
		this.mesIni =  mesActual + 1;
		this.mesFin =  mesActual + 1;		
 		this.cargarInfo();
	},
	watch: {
		mesIni: function (mes) {			
			this.lkpMesesFin = this.lkpMeses.filter((e)=> e.mes >= mes);

			if(mes > this.mesFin) {
				this.mesFin = mes;
			}
		}
	},
	computed: 
	{
		hayDatosParaDescargar() {
			if(this.trxs)
			{
				return (this.trxs.length > 0)
			} else {
				return false;
			}
		},
	},	
	methods: {
		async cargarInfo() {
			this.loading = true;
			try {
				var data = await BanService.GetCuentasXLogin(this.$store.state.auth.currentAppCtx);
				this.lkpCuentas = data;
			
				if(this.lkpCuentas.length > 0) {
					this.IdCuenta = this.lkpCuentas[0].id_cuenta;
				}
			} catch (err) 
			{
				this.lkpCuentas = [];
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000});				
			} finally {
				this.loading = false;
			}
		},
		calcularTotales() {
			var trxs = null;
			
			this.totalIngresos = 0.0;
			this.totalEgresos = 0.0;
			
			trxs = this.trxs;	
			if(trxs) {
				for(let trx of trxs) {
					if(trx.factor == 1) {
						this.totalIngresos += trx.monto;
					} else 
					{
						this.totalEgresos += trx.monto;						
					}
				}
			}
		},						
		formatDocument(Docto)
		{
			return Docto.toString().padStart(6, '0');
		},
		async buscar(){
			this.loading = true;
			try {
                var data = await BanService.getHistorialTrxs(this.$store.state.auth.currentAppCtx,
                    {
                        id: this.IdCuenta,
                        anio: this.anioSel,
						mes_inicial: this.mesIni,
                        mes_final: this.mesFin
                    }
                );

				this.trxs = data;
				this.calcularTotales();
				this.loading = false;
            } catch (err) {
				this.trxs = [];
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000});
            }
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},
		confirmarAnulacionMov(movimiento) {
			this.mov = movimiento;

			this.anularDialog = true;
		},
		anularTrx() {
			BanService.anularMovimiento(this.$store.state.auth.currentAppCtx, this.mov).then(() => {

					this.anularDialog = false;
					// this.mov.anulado = 'S';
					// if(!this.mostrar_anulados){
					// 	this.trxs = this.trxs.filter(val => val.id_movimiento !== this.mov.id_movimiento);					
					// 	this.mov = {};
					// }
					this.$toast.add({severity:'success', summary: 'Éxito', detail: 'La transacción ha sido anulada', life: 3000})
			}
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		descargarPDF(movimiento) {	
			BanService.getFormatoImpresionTrx(this.$store.state.auth.currentAppCtx, movimiento, false).then(
				response => {
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `${movimiento.movimiento_tipo} ${movimiento.serie}-${movimiento.no_docto}.pdf`;
					link.dispatchEvent(new MouseEvent('click'));

					//Open the URL on new Window
					//window.open(fileURL);
				}				
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		filter(e){
			this.filteredData = e.filteredValue;
		},
		async exportarTrxsAXLS()
		{
			this.loading = true;
			try {

				var cuenta = this.lkpCuentas.find((e) => e.id_cuenta == this.IdCuenta);
				var nombreMesIni = this.lkpMeses.find((e)=> e.mes == this.mesIni).nombre;
				var nombreMesFin = this.lkpMeses.find((e)=> e.mes == this.mesFin).nombre;

				var data;
				if(this.filteredData.length > 0) {
					data = this.filteredData;
				} else {
					data = this.$refs.dt.value;
				}
				var rows;

				rows = data.map(doc => {
					return {
							"Fecha": doc.fecha,	
							"TipoDoc": doc.movimiento_tipo,
							"Documento": doc.no_documento,
							"Nombre": doc.nombre,																								
							"Referencia": doc.referencia,
							"Observaciones": doc.observaciones,
							"Moneda": doc.id_moneda,
							"Crédito": (doc.factor==1?doc.monto:null),
							"Débito": (doc.factor==-1?doc.monto:null),
							"Saldo": doc.saldo,
							"Id.Interno": doc.id_movimiento
							}
				}).flat();

				const ws = utils.json_to_sheet(rows);
				var wb = utils.book_new();
				var fileName = `${cuenta.descripcion} - Transacciones - ${this.anioSel}-${nombreMesIni}-${nombreMesFin}`;
				utils.book_append_sheet(wb, ws, `Transacciones`);
				writeFileXLSX(wb, `${fileName}.xlsx`);

				this.loading = false;
			} catch (error)
			{
				this.loading = false;				
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})								
			}	
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">
.anulado {
  color: #bd2130;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: auto;  
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>    

